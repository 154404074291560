html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Patua One', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app, .app, .main {
  height: 100%;
  position: relative;
}

h1, h2 {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}

p {
  margin: 0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}

.slideshow-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.15);
}

.main {
  margin: 20px 40px;
}

@media only screen and (min-width: 768px) {
  .main {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}


.header {
  position: relative;
}

.header .logo {
  width: 320px;
}

.header .select-i18n {
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;
}

.header .select-i18n a {
  text-decoration: none;
  color: #fff;
  font-size: 24px;
}

.banner {
  margin-top: 30px;
}

.banner h1 {
  margin: 10px 0px;
  font-size: 36px;
  font-weight: normal;
  line-height: 36px;
  color: #48a900;
}

.banner h2 {
  margin: 10px 0px;
  font-size: 24px;
  font-weight: normal;
  line-height: 24px;
  color: #fff;
}

.services p {
  margin: 0px;
  font-size: 30px;
  color: #fff;
}

.divider {
  color: #48a900;
}

section, footer {
  margin-top: 65px;
}

.help p {
  font-size: 30px;
  color: #48a900;
}

footer {
  margin-top: 60px;
  font-size: 24px;
  color: #fff;
}

footer a {
  text-decoration: none;
  color: #fff;
}
